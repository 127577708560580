(function flexible (window, document) {
  var docEl = document.documentElement
  var dpr = window.devicePixelRatio || 1
  const defaultWidth = 1920;
  const defaultHeight = 1080;
  // 根据浏览器大小推断缩放比例
// 首先要确定设计稿尺寸，默认是 1920 x 1080
// 分别计算浏览器和设计图宽高比
// 如果浏览器的宽高比大于设计稿的宽高比，就取浏览器高度和设计稿高度之比
// 如果浏览器的宽高比小于设计稿的宽高比，就取浏览器宽度和设计稿宽度之比
const getScale = (w = defaultWidth, h = defaultHeight) => {
  let ww = window.innerWidth / w;
  let wh = window.innerHeight / h;
  return ww < wh ? ww : wh;
};
  // adjust body font size
  function setBodyFontSize () {
    if (document.body) {
      document.body.style.fontSize = (16 * getScale()) + 'px'
    }
    else {
      document.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize();

  // set 1rem = viewWidth / 10
  function setRemUnit () {
    const defaultScale = 1 // 默认比例关系
    let defalutFontSize = 192 // rootValue: 192, //pc端建议：192，移动端建议：75；设计稿宽度的1 / 10
    const getWidth = window.innerWidth // 获取屏幕的宽度
    let currentScale = getScale(defaultWidth, defaultHeight) // 计算当前的屏幕大小和默认宽度之间的比例
    window.pageScale = currentScale;
    // 防止缩放太小
    if (currentScale < 0.85 && getWidth > 1024) {
        //currentScale = 0.855
    }

    // 当前为平板时
    if (getWidth <= 1024) {
       // defalutFontSize = defalutFontSize * 2
    }

    // 计算的宽度比例关系 再 * 默认的字体大小,获取计算的字体大小
    const currentFontSize = (currentScale / defaultScale) * defalutFontSize
    // const scale = 1/dpr, t = 100 * 1/dpr;
    // if (scale != 1)
    // docEl.style.transform = `scale(${scale}) translate(-${t}%, -${t}%) translate3d(0,0,0)`
    docEl.style.fontSize = currentFontSize + 'px'
    setBodyFontSize()
    // console.log(`setRem: innerWidth=${getWidth},innerHeight=${window.innerHeight},dpr=${window.devicePixelRatio},scale=${currentScale},fontSize=${currentFontSize}px`)
  }

  setRemUnit()

  // reset rem unit on page resize
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function (e) {
    if (e.persisted) {
      setRemUnit()
    }
  })

  // detect 0.5px supports
  if (dpr >= 2) {
    var fakeBody = document.createElement('body')
    var testElement = document.createElement('div')
    testElement.style.border = '.5px solid transparent'
    fakeBody.appendChild(testElement)
    docEl.appendChild(fakeBody)
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines')
    }
    docEl.removeChild(fakeBody)
  }
}(window, document))
