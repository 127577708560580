<template>
  <div style="width:100%">
    <!-- 下载组件 -->
    <attachment-download v-if="downloadable && attachments && attachments.length > 0" v-model="attachments"
      :standalone="false" :deletable="deletable" @delete="handleDelete">
      <template #uploadSize="{ file }">
        {{ getUploadedSize(sliceProgresses, file) }}
      </template>
    </attachment-download>
    <!-- 上传组件 -->
    <el-upload v-if="uploadable" v-model:file-list="fileList" :multiple="false" :auto-upload="true" :limit="10"
      :on-exceed="handleExceed" :on-error="uploadError" :show-file-list="false" :http-request="uploadFile"
      :on-success="uploadSuccess" :before-upload="validateFile" accept="*" list-type="text">
      <slot name="button" v-if="customButton">
        <el-icon :size="15">
          <DocumentAdd />
        </el-icon>
      </slot>
      <template #tip v-if="!customButton && !single">
        支持多附件
      </template>
      <template #tip v-else-if="!customButton">
        上传
      </template>

    </el-upload>
    <!-- 上传进度 -->

    <el-progress v-for="p in sliceProgresses" :percentage="p.uploadProgress" :color="customColors">
      <el-button text @click="abortUpload()">任务[{{ p.id }}]<el-icon>
          <RemoveFilled />
        </el-icon></el-button>
    </el-progress>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/modules/app";
import { t } from "@/utils/i18n";

import type { UploadRequestOptions, UploadProps, UploadRawFile, UploadFile, UploadUserFile, UploadFiles } from 'element-plus'

import {
  listInApi, uploadApi, getApi, signApi,
  uploadSliceApi, createOrUpdateApi as saveAttachmentApi,
  getSlicesApi,
  getChunkSizeApi,
  getAbortController
} from "@/api/attachment/index"
import { IAttachment } from "@/api/attachment/types"
import {
  DocumentAdd,
  RemoveFilled
} from '@element-plus/icons-vue';

import SparkMD5 from "spark-md5";

const props = defineProps({
  /**
   * 文件ID
   */
  modelValue: {
    type: String,
    default: ""
  },

  linkId: {
    type: Number,
    default: null
  },
  category: {
    type: String,
    default: "Attachment"
  },
  single: {
    type: Boolean,
    default: false
  },
  downloadable: {
    type: Boolean,
    default: true
  },
  customButton: {
    type: Boolean,
    default: false
  },
  deletable: {
    type: Boolean,
    default: false
  }


});



const app = useAppStore();//统一消息提示入口
const emit = defineEmits(["update:modelValue", "uploaded"]);
const ids = useVModel(props, "modelValue", emit);

const fileList = ref<UploadUserFile[]>([]);

const attachments = ref<IAttachment[]>([]);

const sliceTasks: any = ref([]) //上传任务
const downloadTasks = ref([]) //下载任务

const customColors = [
  { color: '#f56c6c', percentage: 20 },
  { color: '#e6a23c', percentage: 40 },
  { color: '#5cb87a', percentage: 60 },
  { color: '#1989fa', percentage: 80 },
  { color: '#6f7ad3', percentage: 100 },
]

const uploadable = computed(() => {
  if (props.single) {
    if (ids.value) {
      return false;
    }
  }
  return true
});


const sliceProgresses = computed(() => {
  const progresses = sliceTasks.value.filter(task => task.uploadProgress < 100)
  progresses.sort((a, b) => {
    return b.uploadProgress - a.uploadProgress
  })
  if (progresses.length > 3) {
    return progresses.slice(0, 3);
  }
  return progresses;

});
const uploading = computed(() => sliceTasks.value.length > 0);


let abortController = undefined;
let chunkSize = 15;//单位M

onMounted(() => {
  // console.log("upload mounted",ids.value)
  getChunkSize();
  getList();
});

function validateFile(rawFile: UploadRawFile) {

  return rawFile.size > 0;

};

function getList() {
  if (ids.value != undefined && ids.value.trim() != "") {
    const v = ids.value.split(",");
    if (v.length > 0) {
      // console.log("list attachments",ids)
      listInApi(v).then(files => files ? attachments.value = files : attachments.value = []);

    }
  }
}
function getChunkSize() {
  getChunkSizeApi().then(size => chunkSize = size);
}
function uploadError(message: any, file: any, fileList: any) {
  ElMessage.error(message ? message : t("datagrid.message.upload.fail"))
};
function uploadSuccess(message: any, uploadFile: UploadFile, uploadFiles: UploadFiles) {
  emit("uploaded", uploadFile.raw)
  ElMessage.success(message ? message : t("datagrid.message.upload.success"))
};


const handleExceed: UploadProps['onExceed'] = (files) => {
  console.log("exceed:", files)
  ElMessage.error("附件太多了！")
}
function getUploadedSize(uploading, afile) {
  const file = attachments.value.find(attachment => afile.fileName == attachment.fileName);
  if (!file || file.fileSize == file.uploadedSize) {

    return "";
  }
  if (file.verified != undefined && !file.verified) {
    return "校验失败，请删除重新上传";
  }
  const progress = file.uploadedSize! / file.fileSize * 100;

  if (sliceTasks.value.length == 0) {
    return "已上传 " + Math.ceil(progress) + "%， 请重新续传"
  }
  return "已上传 " + Math.ceil(progress) + "%"
}

function abortUpload() {
  if (abortController != undefined) abortController.abort()
  sliceTasks.value = []
}

function signFile(file) {
  const spark = new SparkMD5();
  spark.append(file.lastModified);
  spark.append(file.name);
  spark.append(file.size);
  spark.append(file.type);
  const md5 = spark.end();
  return md5;
}
// slice(从0开始， 结束位置+1)
function signAttachment(file) {
  return new Promise((resolve, reject) => {
    const fileSize = file.size;
    const spark = new SparkMD5.ArrayBuffer();
    const slices: any = []
    const reader = new FileReader();
    reader.onload = (e) => {
      spark.append(e.target.result);
      if (slices.length > 0) {
        reader.readAsArrayBuffer(slices.shift())
      } else {
        const md5 = spark.end();
        resolve(md5);
        //console.log("spark hash:", md5)
      }
    }

    if (fileSize <= 2048 * 1024) {
      slices.push(file)
    } else {
      const end = fileSize - 1;
      const slice1 = file.slice(0, 500 + 1);
      const slice2 = file.slice(end / 2 - 500, end / 2 + 500 + 1);
      const slice3 = file.slice(end - 500, end + 1);
      slices.push(slice1)
      slices.push(slice2)
      slices.push(slice3)
    }
    //console.log("sign file slices", slices)
    if (slices.length > 0) {
      reader.readAsArrayBuffer(slices.shift())
    }
  }

  );
}

function uploadFile({ file, onError, onSuccess }: UploadRequestOptions) {
  if (sliceProgresses.value.length > 0) {
    app.fail("请不要同时上传多个文件")
    return
  }
  const startTime = new Date().getTime();
  sliceTasks.value = []
  const found = attachments.value.find(attachment => file.name == attachment.fileName);
  if (found && file.size == found.uploadedSize) {
    console.warn("already uploaded!")
    onSuccess("已存在")
    return;
  }
  abortController = getAbortController()

  // uid lastModified name size type
  const sliceSize = chunkSize * 1024 * 1024
  const fileSize = found ? file.size - found.uploadedSize : file.size
  const chunks = Math.ceil(fileSize / sliceSize) //总块数
  if (found) {
    console.info("准备上传 %d / %d 字节，%d 切片/%dM，", fileSize, file.size, chunks, chunkSize)
  } else {
    console.info("准备上传 %d 字节，%d 切片/%dM，", file.size, chunks, chunkSize)
  }



  if (!found && chunks == 1) {
    uploadFileDirect(file, onError, onSuccess);
  } else {
    signAttachment(file).then(md5 => {
      const attachment: IAttachment = {
        name: signFile(file),
        linkId: props.linkId,
        category: props.category,
        fileName: file.name,
        fileSize: file.size,
        signedUploaded: md5
      };
      saveAttachmentApi(attachment).then(res => {
        Object.assign(attachment, res);
        //console.log("attachment", attachment)
        if (!found) {
          attachments.value.push(attachment);
          ids.value = attachments.value.map(f => f.id).join(",");
        } else {
          const left = attachments.value.filter(one => found.id != one.id);
          attachments.value = left;
          attachments.value.push(attachment);
        }

        if (attachment.finished) {
          console.info("has finished")
          onSuccess("上传成功");
          return;
        };
        attachment.uploading = true;
        handleSliceTasks(attachment, file, sliceSize).then(
          () => {
            const tasks = [...sliceTasks.value];//上传任务组，切片
            const limitCount = Math.min(10, tasks.length);
            for (let i = 0; i < limitCount; i++) {
              limitUploadQueue(attachment, tasks, onSuccess, onError, startTime);
            }
          }
        );


      }).catch(err => {
        console.error(err);
        if (abortController) {
          abortController.abort();
          abortController = undefined
        }
      });

    })

  }

}

let runningCount = 0;
function handleSliceTasks(attachment, file, sliceSize) {
  runningCount = 0;
  if (attachment.uploadedSize > 0) {
    return getSlicesApi(attachment.id).then(list => {
      let fileSliceBegin = 0;
      console.info("已存在文件分片: ", list)
      for (let i = 0; i < list.length; i++) {
        const sliceMap = list[i];
        if (fileSliceBegin < sliceMap.sliceBegin) {
          const fileSliceEnd = sliceMap.sliceBegin - 1;
          prepareSliceTasks(attachment, file, sliceSize, fileSliceBegin, fileSliceEnd);
        }
        fileSliceBegin = sliceMap.sliceEnd + 1;

      }
      if (fileSliceBegin < file.size) {
        prepareSliceTasks(attachment, file, sliceSize, fileSliceBegin, file.size - 1);
      }
      //console.log("sliceTasks",sliceTasks.value)

    }).catch(err => {
      app.fail("上传失败")
      if (abortController) {
        abortController.abort();
        abortController = undefined
      }
      console.error(err)
    })
  } else {
    prepareSliceTasks(attachment, file, sliceSize, 0, file.size - 1);
    //console.log("sliceTasks",sliceTasks.value)
    return Promise.resolve();
  }

}

function prepareSliceTasks(attachment, file, sliceSize, rangeBegin, rangeEnd) {
  const fileSliceSize = rangeEnd - rangeBegin + 1;
  const sliceChunks = Math.ceil(fileSliceSize / sliceSize) //总块数
  //console.log("prepare", file, sliceSize, rangeBegin, rangeEnd, sliceChunks)

  for (let i = 0; i < sliceChunks; i++) {
    const sliceBegin = i * sliceSize + rangeBegin;
    const bytesEnd = Math.min(file.size, sliceBegin + sliceSize)
    const slice = file.slice(sliceBegin, bytesEnd);
    const id = sliceTasks.value.length
    sliceTasks.value.push({
      id,
      attachmentId: attachment.id,
      file: slice,
      sliceBegin,
      sliceEnd: bytesEnd - 1,
      uploadProgress: 0,
      abort: abortController
    });
    //console.log("slice", slice, sliceBegin, bytesEnd)
  }
}

function limitUploadQueue(attachment, tasks, onSuccess, onError, startTime) {
  if (tasks.length == 0) return;
  const task = tasks.shift();
  const sliceTask = sliceTasks.value.find(t => t.id == task.id)
  if (!sliceTask) return;

  runningCount++;
  uploadFileSlice(sliceTask).then(size => {
    //console.log("slice size", size, sliceTask)

    if (size) {

      sliceTask.abort = undefined
      //sliceTasks.value = sliceTasks.value.filter(t=>t.id!=task.id);
      attachment.uploadedSize += size;
      sliceTask.uploadProgress = 100;
    }
    if (!size) {
      //console.info("data size -1")
      if (abortController && !abortController.signal.aborted) {
        tasks.push(sliceTask)
      }
    }
    runningCount--;
    if (tasks.length == 0 && runningCount == 0) {
      attachment.uploading = false;
      abortController = undefined

      if (attachment.uploadedSize == attachment.fileSize) {
        onSuccess();
      } else
        onError("上传文件异常");
      const duration = new Date().getTime() - startTime;
      console.log("uploads(ms): %d, fileSize: %d, uploadedSize: %d", duration, attachment.fileSize, attachment.uploadedSize)
    }

    if (tasks.length > 0 && abortController && !abortController.signal.aborted) {
      if (!size) {
        setTimeout(() => limitUploadQueue(attachment, tasks, onSuccess, onError, startTime), 1000)
      } else
        limitUploadQueue(attachment, tasks, onSuccess, onError, startTime)
    }
  }).catch(err => {
    attachment.uploading = false;
    if (err && err.code == "ERR_CANCELED") {
      sliceTasks.value = []
      abortController = undefined
    } else {
      console.error("error", err, sliceTask)
      if (abortController) {
        abortController.abort();
        abortController = undefined
      }
    }

  })



}

async function uploadFileSlice(fileSlice) {

  let form = new FormData();
  form.append("file", fileSlice.file);
  form.append("sliceBegin", fileSlice.sliceBegin);
  form.append("sliceEnd", fileSlice.sliceEnd);

  let connecting = true;
  fileSlice.uploadProgress = 0;

  return uploadSliceApi(fileSlice.attachmentId, form,
    (ae: AxiosProgressEvent) => {
      connecting = false
      if (ae.event.lengthComputable) {
        // 0.99 是为了进度条体验更好
        const v = ae.progress * 100 * 0.99;
        if (v > fileSlice.uploadProgress)
          fileSlice.uploadProgress = Math.ceil(v);

      }
    },
    fileSlice.abort
  );

}
function uploadFileDirect(file, onError, onSuccess) {
  const startTime = new Date().getTime();
  signAttachment(file).then((md5: any) => {
    let form = new FormData();
    form.append("file", file);
    form.append("name", signFile(file))
    form.append("signedUploaded", md5)
    if (props.linkId)
      form.append("linkId", props.linkId);
    if (props.category)
      form.append("category", props.category);
    let connecting = true;
    const task = { id: 0, uploadProgress: 0, abort: abortController }
    sliceTasks.value.push(task);
    uploadApi(form,
      (ae: AxiosProgressEvent) => {
        connecting = false
        if (ae.event.lengthComputable) {
          // 0.99 是为了进度条体验更好
          const v = ae.progress * 100 * 0.99;
          if (v > task.uploadProgress)
            task.uploadProgress = Math.ceil(v);
        }
      },
      abortController
    ).then(attachment => {
      abortController = undefined
      task.uploadProgress = 100;
      sliceTasks.value = []
      attachments.value.push(attachment);
      ids.value = attachments.value.map(f => f.id).join(",");
      const duration = new Date().getTime() - startTime;
      console.info("direct upload(ms): %d, size:%d", duration, attachment.uploadedSize)
      onSuccess()

    }).catch(err => {
      if (abortController) {
        abortController.abort();
        abortController = undefined
      }
      sliceTasks.value = []
      console.error(err)
      onError()
    });
  })


}


function handleDelete(attachment) {
  if (attachment.uploading && abortController) {
    abortController.abort();
  }
  const files = fileList.value.filter(f => f.name != attachment.fileName);
  fileList.value = files;
  ids.value = attachments.value.map(f => f.id).join(",");
}



</script>
