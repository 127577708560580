import defaultSettings from "@/settings";

// 导入 Element Plus 中英文语言包

import { store, useSettingsStore } from "@/store";
import { DeviceEnum } from "@/enums/DeviceEnum";
import { SidebarStatusEnum } from "@/enums/SidebarStatusEnum";
import { createSession, Session } from "./session";
import { ISystemInfo } from "@/api/admin/config/types";
import { RemovableRef, useStorage } from "@vueuse/core";
import { Router, RouteRecordRaw } from "vue-router";
import { useMenuStore } from "./menus";
import {t} from "@/utils/i18n"
import i18n from "@/lang";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import variables from "@/styles/variables.module.scss";
import { defineStore } from "pinia";


export const useAppStore = defineStore("app", () => {
  // 设备类型
  const device = useStorage("device", DeviceEnum.DESKTOP);
  // 布局大小
  const size = useStorage("size", defaultSettings.size);
  // 语言
  const language = useStorage("language", defaultSettings.language);
  // 侧边栏状态
  const sidebarStatus = useStorage("sidebarStatus", SidebarStatusEnum.CLOSED);
  const sidebar = reactive({
    opened: sidebarStatus.value === SidebarStatusEnum.OPENED,
    withoutAnimation: false,
  });

  // 顶部菜单激活路径
  const activeTopMenuPath = useStorage("activeTopMenuPath", "");



  // 切换侧边栏
  function toggleSidebar() {
    sidebar.opened = !sidebar.opened;
    sidebarStatus.value = sidebar.opened ? SidebarStatusEnum.OPENED : SidebarStatusEnum.CLOSED;
  }

  // 关闭侧边栏
  function closeSideBar() {
    sidebar.opened = false;
    sidebarStatus.value = SidebarStatusEnum.CLOSED;
  }

  // 打开侧边栏
  function openSideBar() {
    sidebar.opened = true;
    sidebarStatus.value = SidebarStatusEnum.OPENED;
  }

  // 切换设备
  function toggleDevice(val: string) {
    device.value = val;
  }

  /**
   * 改变布局大小
   *
   * @param val 布局大小 default | small | large
   */
  function changeSize(val: string) {
    size.value = val;
  }
  /**
   * 切换语言
   *
   * @param val
   */

  function changeLanguage(val: string) {
    language.value = val;
    i18n.global.locale.value = val;
  }
  /**
   * 混合模式顶部切换
   */
  function activeTopMenu(val: string) {
    activeTopMenuPath.value = val;
  }
  
  const systeminfo = ref();
  function setSystemInfo(val: ISystemInfo) {
    systeminfo.value = val;
  }

  const screenBox = ref({ width: 0, height: 0 })
  const navbarHeight = 50
  const tagsViewHeight = 34
  const paddingHeight = 15 * 2
  const pagingBarHeight = 40
  const filterBarHeight = 40
  function getMainHeight(hasPagingBar=false,filterBarCount=0) {
    let h = screenBox.value.height - navbarHeight - paddingHeight
    if (useSettingsStore().tagsView) {
      h -= tagsViewHeight;
    } 
    if (hasPagingBar) {
      h -= pagingBarHeight;
    }
    h -= filterBarHeight * filterBarCount;
    return h
  }

  const session: RemovableRef<Session> = useStorage("session", null, sessionStorage, {
    serializer: {
      read: (v: any) => {
        const s = v ? JSON.parse(v) : null
        return createSession(s.token, s.accessed);
      },
      write: (v: Session) => {
        if (!v) return ""
        const s = { token: v.id, accessed: v.getLastAccessed() }
        return JSON.stringify(s)
      },
    }
  });

  function newSession(token: string) {
    session.value = createSession(token);
  }
  function getSession() {
    if (null != session.value) {
      if (!session.value.isExpired()) {
        session.value.updateTime();
      } else {
        console.log("session expired")
        session.value = null;
      }
    }

    return session.value;
  }

  function login(relogin): Promise<void> {
    addRoutes(useMenuStore().routes)
    if (!relogin) {
      currentPath.value = { path: "/i" };
    }
    jumpToDefault()

    return Promise.resolve();
  }
  function logout() {
    message.count = 0;
    pageTitle.value = "";
    session.value = null;
    useMenuStore().logout();
    clear_auth();
    if (router != undefined) {
      router.replace({ path: "/login" });
    }

  }
  function toLogin() {
    if (router != undefined) {
      router.replace({ path: "/login" });
    }
  }

  const currentPath = useStorage("currentPath", {path:"/"});

  
  const message = reactive({ count: 0 });
  let pageTitle = ref("");

  let router;//路由功能

  /**
   * 注入全局路由器
   * @param appRouter 
   */
  async function setupRouter(r: Router): Promise<void> {
    if (!router) {
      router = r;
    }
    router.addRoute({ path: '/:catchAll(.*)', redirect: "/login" });
    //await useMenuStore().initMenus()
    webguard();
  }
  function webguard() {
    NProgress.configure({ showSpinner: false }); // 进度条
    // 白名单路由
    const whiteList = ["/login", "/register"];

    router.beforeEach(async (to, from, next) => {
      NProgress.start();
      //console.log("route ", to, from)
      const hasToken: boolean = useAppStoreHook().getSession() != null;
      if (hasToken) {
        if (to.path === "/login") {
          //console.log("has logged in , to /")
          // 如果已登录，跳转首页
          next({ path: "/" });
          NProgress.done();
        } else {
          //判断 加载菜单
          //await useMenuStoreHook().initMenus();
          //console.log("has logged in ",to.path)
          // 未匹配到任何路由，跳转404
          if (to.matched.length === 0) {
            from.name ? next({ name: from.name }) : next("/404");
          } else {
            useAppStoreHook().setCurrentPath(to);
            next();
          }
        }
      } else {
        console.log("not logged in ", to)
        // 未登录可以访问白名单页面
        if (whiteList.indexOf(to.path) !== -1) {
          next();
        } else {
          useAppStoreHook().setCurrentPath(to);
          next("/login");
          NProgress.done();
        }
      }
    });

    router.afterEach(() => {
      NProgress.done();
    });

  }
  /**
   * 路由跳转
   * @param path 
   */
  function jumpTo(path: RouteRecordRaw) {
    console.log("jump to:", path)
    return router.push(path);
  }
  /**
   * 返回默认页面
   */
  function jumpToDefault() {
    console.log("currentPath:", currentPath.value)
    try {
      return router.push(currentPath.value);
    } catch (e) {
      return router.push("/");
    }
  }

  /**
   * 增加路由
   * @param routes 
   */
  function addRoutes(routes: RouteRecordRaw[]) {
    if (router && routes) {
      routes.forEach(r => {
        router.addRoute(r);
      })
    }
  }
  function setCurrentPath(to) {
    let path = {
      fullPath: to.fullPath,
      hash: to.hash,
      meta: JSON.parse(JSON.stringify(to.meta)),
      name: to.name,
      params: to.params,
      path: to.path,
      query: to.query,
      // 子级路由tag，必须具有一层父级路由信息
      childeRouter: [
        {
          fullPath: to.fullPath,
          hash: to.hash,
          meta: JSON.parse(JSON.stringify(to.meta)),
          name: to.name,
          params: to.params,
          path: to.path,
          query: to.query,
        }
      ]
    }

    currentPath.value = path;
  }

  /**
   * 业务级报错处理
   * response code 417
   */
  function sendErrorReport(error: any) {
    if (error.code && t("error."+error.code) !== "error."+error.code) {//翻译错误码
      const args:any = {}

      if (error.data) {
        const argCount = error.data["arg-count"]
        if (argCount) {
          for (let i = 0; i < argCount; i++) {
            args["a"+i]=error.data["arg" + (i)]
          }
        }
      }
      const msg = t("error."+error.code,args)
      fail(msg)
    } else {
      fail(error.message ?? error)
    }
  }

  function success(msg: string | undefined) {
    if (undefined == msg) msg = '操作成功';

    ElMessage.success({ message: msg, type: 'success', duration: 2000 })
  }
  function fail(msg: string | undefined) {
    if (undefined == msg) msg = '操作失败'
    ElMessage.error({
      message: msg, type: 'error', dangerouslyUseHTMLString: true,
      customClass: 'errorInfo', duration: 2000
    })
  }
  function cancel(msg: string | undefined) {
    if (undefined == msg) msg = '取消操作'
    ElMessage.info({ message: msg, type: 'info', duration: 2000 })
  }
  function info(msg: string | undefined) {
    if (undefined == msg) return
    ElMessage.info({ message: msg, type: 'info' })
  }
  function createXMLObject() {
    let xmlhttp;
    try {
      if (window.XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
      }
      // code for IE5、IE6
      else if (window.ActiveXObject) {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
      }
    }
    catch (e) {
      xmlhttp = false;
    }
    return xmlhttp;
  }

  function clear_auth() {
    try {
      if (navigator.userAgent.indexOf("MSIE") > 0)         //IE浏览器实现注销功能
      {
        document.execCommand("ClearAuthenticationCache");
      }
      else if (navigator.userAgent.indexOf("Firefox") > 0)       //Firefox实现注销功能
      {
        var xmlhttp = createXMLObject();
        xmlhttp.open("GET", ".force_logout_offer_login_mozilla", true, "logout", "logout");
        xmlhttp.send("");
        xmlhttp.abort();
      }
      else       //Google Chrome等浏览器实现注销功能
      {
        let xmlHttp;
        if (window.XMLHttpRequest) {
          xmlHttp = new XMLHttpRequest();
        }
        xmlHttp.open("GET", "/index.html#logout", false);
        xmlHttp.send(null);
        xmlHttp.abort();
      }
    }
    catch (e) {
      alert("there was an error");
      return false;
    }
  }

  return {
    device,
    sidebar,
    language,
    size,
    activeTopMenu,
    toggleDevice,
    changeSize,
    changeLanguage,
    toggleSidebar,
    closeSideBar,
    openSideBar,
    activeTopMenuPath,
    screenBox,
    getMainHeight,
    systeminfo,
    setSystemInfo,
    message,
    pageTitle,

    addRoutes,
    jumpTo,
    jumpToDefault,
    setCurrentPath,
    login,
    logout,
    toLogin,

    setupRouter,
    newSession,
    getSession,

    success,
    fail,
    cancel,
    info,
    sendErrorReport

  };
});

/**
 * 用于在组件外部（如在Pinia Store 中）使用 Pinia 提供的 store 实例。
 * 官方文档解释了如何在组件外部使用 Pinia Store：
 * https://pinia.vuejs.org/core-concepts/outside-component-usage.html#using-a-store-outside-of-a-component
 */
export function useAppStoreHook() {
  return useAppStore(store);
}
