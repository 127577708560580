<template>
  <div class="download-box" style="width:100%;height:100%;">
    <el-row v-if="standalone && attachments.length>0">
      <el-col :span="24"> 附件<span style="font-size:15px;font-weight:bold;padding-left:5px;">:</span>     </el-col>
    </el-row>
    <el-row v-for="file in attachments" :key="file.id">
      <el-col :span="17">
      <el-button link type="primary"  @click="handleDownload(file)">{{file.fileName}}</el-button>
      </el-col>

      <el-col :span="6">
      <slot name="uploadSize" :file="file">
      {{getUploadedSize(file)}}
      </slot>
      {{getDownloadedSize(file)}}</el-col>

      <el-col :span="1"  v-if="deletable">
        <el-button :icon="Close" link type="danger"  size="large" @click="handleDelete(file)"/>
      </el-col>
      <el-col :span="1" v-else>
        <el-button  :icon="Download" link type="danger"  size="large" @click="handleDownload(file)"/>
      </el-col>

      <el-col :span="24" v-if="file.thumbnail != undefined">
      <img  @click="handleDownloadA(file)" :src="file.thumbnail" />
      </el-col>

      <el-col  :span="24" >
        <!-- 下载进度 -->
        <el-progress :percentage="file.downloadProgress"  :color="customColors" v-if="file.downloadProgress>=0&&file.downloadProgress<100">
          <el-button text @click="abortDownload(file)">{{file.downloadProgress}}%<el-icon><RemoveFilled/></el-icon></el-button>
        </el-progress>

        <el-progress v-for="(p,index) in downloadProgresses" :key="index" :percentage="p.downloadProgress" :color="customColors" v-if="file.downloading">
          <el-button text @click="abortDownload()">任务[{{p.id}}]<el-icon><RemoveFilled/></el-icon></el-button>
        </el-progress>
      </el-col>
    </el-row>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/modules/app";
import { t } from "@/utils/i18n";


import {
  headAttachmentApi,
  inlineAttachmentApi,
  downloadAttachmentSliceApi,
  deleteApi, downloadAttachmentApi,
  downloadAttachmentURL,
  getChunkSizeApi,
  getAbortController
  } from "@/api/attachment/index"
import {IAttachment} from "@/api/attachment/types"
import {
  Download,
  RemoveFilled,
  Close
} from '@element-plus/icons-vue';
import {saveAs} from "file-saver";
import streamSaver from 'streamsaver'
import { AxiosProgressEvent } from "axios";
streamSaver.mitm = "/mitm.html"

const props = defineProps({
  /**
   * 文件ID
   */
  modelValue: {
    type: Object as PropType<Partial<IAttachment>[]>,
    default: []
  },
  standalone: {
    type: Boolean,
    default: true
  },
  deletable: {
    type: Boolean,
    default: false
  },

});



const app = useAppStore();//统一消息提示入口
const emit = defineEmits(["update:modelValue","delete"]);
const attachments = useVModel(props, "modelValue", emit);
const standalone = useVModel(props, "standalone");
const deletable = useVModel(props, "deletable");

const customColors = [
  { color: '#f56c6c', percentage: 20 },
  { color: '#e6a23c', percentage: 40 },
  { color: '#5cb87a', percentage: 60 },
  { color: '#1989fa', percentage: 80 },
  { color: '#6f7ad3', percentage: 100 },
]
interface IDownloadTask {
  
              id: number,
              attachmentId: number,
              sliceBegin:number,
              sliceEnd: number,
              downloadProgress: number,
              abort?: AbortController
            }

const downloadTasks = ref<IDownloadTask[]|undefined>([])

const downloadProgresses = computed(()=>{
  const progresses = downloadTasks.value?.filter(task=>task.downloadProgress<100)
  progresses?.sort((a,b)=>{
    return b.downloadProgress-a.downloadProgress
  })
  if (progresses!.length > 3) {
    return progresses?.slice(0,3);
  }
  return progresses;

});

let abortController:AbortController|undefined = undefined;
let chunkSize = 15;//单位M

onMounted(()=>{
   getChunkSize();
});

function getChunkSize() {
  getChunkSizeApi().then(size=>chunkSize=size);
}
function getUploadedSize(file) {
  if (file.fileSize == file.uploadedSize) {
    return "";
  }
  if (file.verified != undefined && !file.verified) {
    return "校验失败，请删除重新上传";
  }
  const progress = file.uploadedSize / file.fileSize * 100;

  return "已上传 "+Math.ceil(progress) + "%， 请重新续传"
}


function abortDownload(task?) {
  if (abortController!=undefined) abortController.abort()
  downloadTasks.value = []
  if (task) task.downloadProgress = 101
}

function getDownloadedSize(file) {
  if (!file.downloadedSize || file.fileSize == file.downloadedSize) return "";
  const progress = file.downloadedSize / file.fileSize * 100;
  console.log("file:",file)
  return "已下载 "+Math.ceil(progress) + "%"
}
function handleDownload(attachment) {
  downloadTasks.value = []
  attachments.value = attachments.value.map(attachment=>{
      attachment.downloading=false
      return attachment
    });
  if (attachment.downloadedSize == undefined) {
    attachment.downloadedSize = 0;
  }
  if (attachment.fileSize > attachment.uploadedSize) {
    app.fail("未上传完不能下载");
    return
  }
  
  headAttachmentApi(attachment.id).then(headers=>{
        const acceptRanges = headers["accept-ranges"];
        const contentLength = parseInt(headers["content-length"])
        //console.log("ranges", acceptRanges, contentLength)
        abortController = getAbortController()
        attachment.downloading = true
        if (acceptRanges=="bytes" && contentLength > chunkSize * 1024 *1024) {
          const startTime = new Date().getTime();
          //多线程下载, 浏览器只有一个线程，多线程是没有意义的
          const sliceSize = chunkSize * 1024 * 1024
          let downloadRange = contentLength - attachment.downloadedSize
          const chunks = Math.ceil(downloadRange / sliceSize) //总块数
          console.info("分片下载 %d切片/%dM, start from %d", chunks, chunkSize,attachment.downloadedSize)
          for ( let i = 0; i < chunks; i++) {
            const sliceBegin = i * sliceSize + attachment.downloadedSize;
            const bytesEnd = Math.min(contentLength, sliceBegin + sliceSize )
            downloadTasks.value!.push({
              id: i,
              attachmentId: attachment.id,
              sliceBegin,
              sliceEnd: bytesEnd-1,
              downloadProgress: 0,
              abort: abortController
            });
            //console.log("slice", slice, sliceBegin, bytesEnd)
          }
          const tasks = [...downloadTasks.value!]  ;//下载任务组，切片
          if (attachment.writer == undefined) {
            let fileStream = streamSaver.createWriteStream(attachment.fileName,{size: attachment.fileSize})
            attachment.writer = fileStream.getWriter()
          }
          

          limitDownloadQueue(attachment, tasks, startTime);
        } else {
          console.info("单线程下载...")
          downloadDirect(attachment)
        }
      });
}
function writeStream(attachment, data) {
 const readableStream = data.stream()

  const reader = readableStream.getReader()
  const pump = () => reader.read()
    .then(res => {
      if (!res.done) {
        return attachment.writer.write(res.value).then(pump)
      }
      else {
        return Promise.resolve();
      }
    })

  return pump()

}
function limitDownloadQueue(attachment, tasks, startTime) {
    if (tasks.length == 0) return;
    const task = tasks.shift();
    const sliceTask = downloadTasks.value!.find(t=>t.id==task.id)
    if (!sliceTask) return;
    downloadFileSlice(sliceTask).then(data=>{
      //console.log("download success", task)
      if (data.size > 0) {
        //sliceTask.data = data;
        writeStream(attachment, data).then(()=>{
          sliceTask.downloadProgress = 100;
          sliceTask.abort = undefined
          attachment.downloadedSize +=data.size          
          if (tasks.length==0 && attachment.downloadedSize ==attachment.fileSize) {
            abortController = undefined
            attachment.downloading = false;
            console.log("complete")
            if (attachment.writer) {
                console.log("close writer")
                attachment.writer.close();
                attachment.writer = undefined
            }
              
            downloadTasks.value = undefined;
            app.success("下载完成")
            const duration = new Date().getTime() - startTime;
            console.info("%d bytes下载完成。: download(ms): %d ", attachment.downloadedSize, duration)
            attachment.downloadedSize = undefined
            
          } else if (tasks.length>0 && abortController && !abortController.signal.aborted) {
            limitDownloadQueue(attachment, tasks, startTime)
          } 
        })
       
      
      }

      if (data.size == 0) {
        //console.info("data size 0")
        if (abortController && !abortController.signal.aborted) {
          tasks.push(sliceTask)
          setTimeout(()=>limitDownloadQueue(attachment, tasks, startTime), 1000);
        }
      }
      
    }).catch(err=>  {
      if (err && err.code =="ERR_CANCELED") {
           //app.fail("中止下载，后续可继续下载");
           downloadTasks.value = []
           abortController = undefined
           attachment.downloading = false;      
      } else {
        if (abortController) {
          abortController.abort();
          abortController = undefined
        }
        console.error(err);
        app.fail("下载失败，请重新下载");
      }
    })
      
  


}
function downloadFileSlice(task) {

  task.downloadProgress = 0;
  let connecting = true;

 // console.log("download", task)
  return downloadAttachmentSliceApi(task.attachmentId,task.sliceBegin, task.sliceEnd, 
      (ae: AxiosProgressEvent) => {
        connecting = false
        if (ae.event.lengthComputable) {
          const v = ae.progress! * 100 * 0.99;
          if (v>task.downloadProgress)
            task.downloadProgress = Math.ceil(v);
          // 0.99 是为了进度条体验更好
        }
      },
      task.abort
  )
  
}
function downloadDirect(attachment) {

  attachment.downloadProgress = 0;
  let connecting = true;
  const startTime = new Date().getTime();
  inlineAttachmentApi(attachment.id,
    (ae: AxiosProgressEvent) => {
      connecting = false
      if (ae.event.lengthComputable) {
        const v = ae.progress! * 100 * 0.99;
        if (v>attachment.downloadProgress)
          attachment.downloadProgress = Math.ceil(v);
        // 0.99 是为了进度条体验更好
      }
    },
    abortController
  ).then(data=>{
     if (data.size==0) {
      //console.log("data size 0")
      app.fail("服务器超载，下载失败")
      abortController = undefined
      attachment.downloadProgress = 100;
      return;
    }
    abortController = undefined
    attachment.downloadProgress = 100;
    attachment.downloadedSize = data.size;
    app.success("下载完成")
    if (data && !data.downloaded)
    saveAs( data,  attachment.fileName );
    
    const duration = new Date().getTime() - startTime;
    console.info("%d bytes下载完成。: download(ms): %d ", attachment.downloadedSize, duration)
  }).catch(err => {  if (!err.ignore) console.log(err); })
  
}
function handleDownloadA(attachment) {
    console.log("内联下载...");
    const url = downloadAttachmentURL(attachment.id);
    const a = document.createElement("a");
    a.href = url;
    a.target="_blank"
    a.setAttribute('download', attachment.fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(a.href);
  
}

function handleDelete(attachment) {
  deleteApi(attachment.id).then(res=>{
    const remainder = attachments.value.filter(f=>f.id!=attachment.id)
    attachments.value = remainder;

    emit("delete", attachment);
  })
}


</script>
<style lang="scss" scoped>
.download-box {
  display:flex;
  flex-direction: column;
  align-items: center;

}
</style>
