import type { PageContent } from '@/api/types';
import request from "@/utils/request";
import {appendToken} from "@/utils/request";
import type { AxiosProgressEvent, AxiosResponseHeaders } from "axios";
import type { IAttachment } from "./types";


/**
 * 
 * @returns 数据不多，就不用分页了
 */
export function listApi(queryParams: Object): Promise<PageContent> {
    return request({
        url: "/admin/attachments",
        method: 'get',
        params: queryParams
    });
}
export function getApi(id: number|string): Promise<IAttachment> {
    return request({
        url: `/admin/attachments/${id}`,
        method: 'get',
    });
}
/**
 * 
 * @returns 单位 M
 */
export function getChunkSizeApi(): Promise<number> {
    return request({
        url: '/admin/attachments/chunk-size',
        method: 'get',
    });
}
export function getSlicesApi(id: number): Promise<IAttachment[]> {
    return request({
        url: `/admin/attachments/${id}/slices`,
        method: 'get',
    });
}
/**
 * @param data 
 * @returns id for data
 */
export function createOrUpdateApi(data: IAttachment): Promise<IAttachment> {
    return request({
        url: "/admin/attachments/info",
        method: 'post',
        data: data
    });
}

export function signApi(id: number, signedString: String): Promise<boolean> {
    return request({
        url: `/admin/attachments/${id}`,
        method: 'post',
        data: signedString
    });
}
export function getAbortController() {
    return new AbortController();
}
export function uploadApi(data:any,handler?:(event:AxiosProgressEvent)=>void|undefined, abort?:AbortController): Promise<IAttachment>{
    return request({
        url: `/admin/attachments`,
        method: 'post',
        transformRequest: [function(data, headers) {
            // 去除post请求默认的Content-Type
            delete headers['Content-Type']
            return data
          }],
        headers:  { enctype: 'multipart/form-data' },
        data: data,
        onUploadProgress: handler,
        signal: abort?.signal,
        timeout: 50000
    });
}
export function uploadSliceApi(id:number, data:any,handler?:(event:AxiosProgressEvent)=>void|undefined, abort?:AbortController): Promise<number>{
    return request({
        url: `/admin/attachments/${id}/slice`,
        method: 'post',
        transformRequest: [function(data, headers) {
            // 去除post请求默认的Content-Type
            delete headers['Content-Type']
            return data
          }],
        headers:  { enctype: 'multipart/form-data' },
        data: data,
        onUploadProgress: handler,
        signal: abort?.signal,
        timeout: 50000
    });
}

export function downloadAttachmentApi(id: number, handler?:(event:AxiosProgressEvent)=>void|undefined, abort?:AbortController): Promise<Blob> {
    return request({
        url: `/admin/attachments/${id}/download`,
        method: 'get',
        responseType: "blob",
        onDownloadProgress: handler,
        signal: abort?.signal,
        timeout: 50000
    });
}
export function downloadAttachmentSliceApi(id: number, rangeBegin:number, rangeEnd: number, handler?:(event:AxiosProgressEvent)=>void|undefined, abort?:AbortController): Promise<Blob> {
    return request({
        url: `/admin/attachments/${id}/download`,
        method: 'get',
        responseType: "blob",
        headers:  { range: `bytes=${rangeBegin}-${rangeEnd}` },
        onDownloadProgress: handler,
        signal: abort?.signal,
        timeout: 50000
    });
}

export function inlineAttachmentApi(id: number, handler?:(event:AxiosProgressEvent)=>void|undefined, abort?:AbortController): Promise<Blob> {
    return request({
        url: `/admin/attachments/${id}/inline`,
        method: 'get',
        responseType: "blob",
        onDownloadProgress: handler,
        signal: abort?.signal,
        timeout: 50000
    });
}
export function headAttachmentApi(id: number): Promise<AxiosResponseHeaders> {
    return request({
        url: `/admin/attachments/${id}/download`,
        method: 'head',
    });
}
export function downloadAttachmentURL(id: number): string {
    return appendToken(`/admin/attachments/${id}/inline`);
}
export function downloadOpenAttachmentURL(id: number): string {
    return appendToken(`/admin/attachments/${id}/show`);
}

export function listInApi(ids: number[]): Promise<IAttachment[]> {
    return request({
        url: "/admin/attachments/in",
        method: 'post',
        data: ids
    });
}
export function deleteApi(id: number): Promise<boolean> {
    return request({
        url: `/admin/attachments/${id}`,
        method: 'delete',
    });
}